<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="[`${$route.params.packageTitle}`, 'Activities']" />
            </div>
          </div>

          <h3><b>Activites:</b></h3>

          <div class="search_add_section">
            <div>
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by title"
                class="search_by_input"
                spellcheck="off"
              />
            </div>
            <div>
              <AddSafariPackageActivityDialog
                @refreshActivities="getActivities"
                :packageId="$route.params.packageId"
              />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || deleting"
            class="table-responsive"
          >
            <data-tables
              :data="activities"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading  && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getActivities"
                  v-else-if="!isLoading  && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Activities Now. Please click here to retry
                </div>
                <div v-if="!isLoading  && !loadingError">No Activities</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="50px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Title"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.title }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Date Added"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  {{ scope.row.date_added }}
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        @click="showEditPackageDialog(scope.row)"
                        type="warning"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        @click="deleteActivity(scope.row.id)"
                        type="danger"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <EditActivityDialog
      :isEditActivityVisible="isEditActivityVisible"
      :packageId="$route.params.packageId"
      :activityData="activityData"
      @closeEditActivityDialog="closeEditActivityDialog"
      @refreshActivities="getActivities"
    />
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import AddSafariPackageActivityDialog from "../components/dialogs/add-safari-package-activity-dialog.vue";
import EditActivityDialog from "../components/dialogs/edit-activity-dialog.vue";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
export default {
  components: {
    AddSafariPackageActivityDialog,
    EditActivityDialog,
    ScaleOut,
    BreadCrumbComponent,
  },

  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,
      deleting: false,
      isEditActivityVisible: false,

      activities: [],
      activityData: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "room_name",
          value: "",
        },
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getActivities();
  },

  methods: {
    showEditPackageDialog(activityData) {
      this.isEditActivityVisible = true;
      this.activityData = activityData;
    },

    closeEditActivityDialog() {
      this.isEditActivityVisible = false;
    },

    async getActivities() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/safari/package/activities`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.activities = request.data.activities;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },

    async switchStatus(activation_status) {
      let staffStatus = {
        is_active: activation_status,
      };
      try {
        this.deleting = true;
        let request = await this.$http.patch(
          `staff/change_active`,
          staffStatus
        );
        if (
          request.data.success &&
          request.data.message == "STAFF_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getStaff();
          this.showSuccessMessage("Success", "Staff status changed");
          this.deleting = false;
        } else if (
          request.data.message == "STAFF_ACTIVE_STATUS_COULD_NOT_BE_CHANGED"
        ) {
          this.showWarningMessage("Failed", "Staff status unable to change");
          this.deleting = false;
        } else if (request.data.message == "STAFF_ACTIVE_STATUS_IS_REQUIRED") {
          this.showWarningMessage("Failed", "Staff status required");
          this.deleting = false;
        } else if (
          request.data.message == "ONLY_APPROVED_STAFF_CAN_BE_ACTIVATED"
        ) {
          this.showWarningMessage(
            "Approve First",
            "Only Approved Staff Members can be activated/deactivated. Please approve them first."
          );
          this.deleting = false;
          console.log(this.is_active);
          // this.is_active = false;
        } else if (
          request.data.message == "ONLY_APPROVED_STAFF_MEMBERS_CAN_BE_ACTIVATED"
        ) {
          this.showWarningMessage(
            "Approve First",
            "Only Approved Staff Members can be activated/deactivated. Please approve them first."
          );
          this.deleting = false;
          console.log(this.is_active);
          // this.is_active = false;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.deleting = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change staff status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.deleting = false;
      }
    },

    async deleteActivity(id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Activity. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(
              `api/safari/package/activity/delete/${id}`
            );
            if (
              request.data.success &&
              request.data.message === "ACTIVITY_DELETED_SUCCESSFULLY"
            ) {
              this.getActivities();
              this.$notify({
                title: "Success",
                message: "Activity deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete Activity, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

input:focus {
  border: 0.01em solid #409eff !important;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>